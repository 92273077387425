import axios from "axios";
import Vue from "vue";
import * as Vuex from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import isEmpty from "./../util/isEmpty";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import moment from "moment";

Sentry.init({
  release: "Homeowner-UI@1.2.3",
  dsn: "https://5e29a0226e3b439abc24f27e3c6fc078@sentry.io/1846746",
  integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
  environment: process.env.NODE_ENV || "local",
});

// Interfaces
import Home from "@/interfaces/dashboard/home.interface.ts";
import Profile from "@/interfaces/dashboard/profile.interface";
import stateStore from "@/interfaces/state.interface.ts";

function determineProductType(productTypes: any, sectionInfo: any): string {
  for (const specLevel of Object.getOwnPropertyNames(productTypes)) {
    for (const item of productTypes[specLevel]) {
      if (item === sectionInfo.specLevel) {
        return specLevel;
      }
    }
  }
  return "";
}

export const storeState = {
  namespaced: true,
  state: {
    documents: {},
    homes: [],
    selectedHome: {},
    isNavDisabled: false,
    alert: {
      show: false,
    },
    userProfile: {
      name: "",
      email: "",
      phoneHome: "",
      phoneWork: "",
      relationID: "",
    },
    hoaContacts: [],
    security: {
      userID: "",
      accessToken: "",
      expiration: 0,
      refreshToken: "",
      successfulLogin: false,
      code: 0,
      role: "",
      multipleHomes: false,
      servicesAccess: [],
    },
    changeEmail: {
      data: {},
      show: false,
    },
    addUser: {
      data: {},
      show: false,
    },
    uploadFile: {
      data: {},
      show: false,
    },
    showSurveys: {
      show: false,
    },
    surveyUser: {
      name: "",
      email: "",
      phoneHome: "",
      phoneWork: "",
      relationID: "",
    },
    jobInfo: {
      jobNumber: "",
      division: "",
      community: "",
      productType: "",
      agreementWritten: "",
      company: "",
    },
    adminMode: false,
    surveys: {},
    relatedSurveys: {},
    HTTP(token: string, baseURL?: string) {
      return axios.create({
        headers: {
          authorization: `Bearer ${token}`,
          token,
        },
        baseURL: baseURL || process.env.VUE_APP_BASE_URL || "",
      });
    },
    localStorage: window.localStorage,
    mobileSideBar: {
      show: false,
    },
  },
  getters: {
    getShowAlert(state: any): boolean {
      return state.alert.show;
    },
    getIsNavDisabled(state: any): boolean {
      return state.isNavDisabled;
    },
    getChangeEmailData(state: any): any {
      return state.changeEmail.data;
    },
    getShowChangeEmail(state: any): boolean {
      return state.changeEmail.show;
    },
    getAddUserData(state: any): any {
      return state.addUser.data;
    },
    getShowAddUser(state: any): boolean {
      return state.addUser.show;
    },
    getUploadFileData(state: any): any {
      return state.uploadFile.data;
    },
    getShowUploadFile(state: any): boolean {
      return state.uploadFile.show;
    },
    getShowSurveys(state: any): boolean {
      return state.showSurveys.show;
    },
    getSurveyUser(state: any): any {
      return state.surveyUser;
    },
    getHomes(state: any): Home[] {
      if (state.localStorage.getItem("homes")) {
        return JSON.parse(state.localStorage.getItem("homes"));
      } else {
        return state.homes || [];
      }
    },
    getSelectedHome(state: any): any {
      if (isEmpty(state.selectedHome) && state.localStorage.getItem("selectedHome")) {
        return JSON.parse(state.localStorage.getItem("selectedHome"));
      } else {
        return state.selectedHome || "";
      }
    },
    getName(state: any): string {
      return state.userProfile.name || "-";
    },
    getEmail(state: any): string {
      return state.userProfile.email || "-";
    },
    getHOAContacts(state: any): any[] {
      return state.hoaContacts || [];
    },
    getPhoneHome(state: any): string {
      return state.userProfile.phoneHome || "-";
    },
    getPhoneWork(state: any): string {
      return state.userProfile.phoneWork || "-";
    },
    getUserProfile(state: any): any {
      return state.userProfile;
    },
    getUserRole(state: any): string {
      if (!state.security.role && state.localStorage.getItem("role")) {
        return state.localStorage.getItem("role");
      } else {
        return state.security.role;
      }
    },
    getToken(state: any): string {
      if (!state.security.accessToken && state.localStorage.getItem("accessToken")) {
        return state.localStorage.getItem("accessToken");
      } else {
        return state.security.accessToken;
      }
    },
    getServicesAccess(state: any): any {
      if (state.security.servicesAccess && state.localStorage.getItem("servicesAccess")) {
        return JSON.parse(state.localStorage.getItem("servicesAccess"));
      } else {
        return state.security.servicesAccess;
      }
    },
    getRefreshToken(state: any): string {
      if (!state.security.refreshToken && state.localStorage.getItem("refreshToken")) {
        return state.localStorage.getItem("refreshToken");
      } else {
        return state.security.refreshToken;
      }
    },
    getTokenExpiration(state: any): number {
      if (!state.security.expiry && state.localStorage.getItem("tokenExpiration")) {
        return state.localStorage.getItem("tokenExpiration");
      } else {
        return state.security.expiry;
      }
    },
    getUserID(state: any): string {
      if (!state.security.userID && state.localStorage.getItem("userID")) {
        return state.localStorage.getItem("userID");
      } else {
        return state.security.userID;
      }
    },
    getCode(state: any): number {
      return state.security.code;
    },
    getDocuments(state: any): any {
      if (isEmpty(state.documents)) {
        return { homeDocuments: [], useGuides: [], serviceDocuments: [], communityDocuments: [] };
      } else {
        return state.documents;
      }
    },
    getMultipleHomes(state: any): boolean {
      if (!state.security.multipleHomes && state.localStorage.getItem("multipleHomes")) {
        return JSON.parse(state.localStorage.getItem("multipleHomes"));
      } else {
        return state.security.multipleHomes;
      }
    },
    getJobInfo(state: any): any {
      if (state.jobInfo.jobNumber === "" && state.localStorage.getItem("jobInfo")) {
        return JSON.parse(state.localStorage.getItem("jobInfo"));
      } else {
        return state.jobInfo;
      }
    },
    getAdminMode(state: any): boolean {
      if (isEmpty(state.selectedHome) && state.localStorage.getItem("adminMode")) {
        return JSON.parse(state.localStorage.getItem("adminMode"));
      } else {
        return state.adminMode;
      }
    },
    getSurveys(state: any): any {
      return state.surveys;
    },
    getRelatedSurveys(state: any): any {
      return state.relatedSurveys;
    },
    getMobileSideBar(state: any): any {
      return state.mobileSideBar;
    },
    getJUser(state: any): boolean {
      if (state.userProfile.email === "" && state.localStorage.getItem("userProfile")) {
        return JSON.parse(state.localStorage.getItem("userProfile")).email === "juser@fischerhomes.com";
      } else {
        return state.userProfile.email === "juser@fischerhomes.com";
      }
    },
    getIsRebatesDisabled(state: any): boolean {
      const threeYearsAgo = moment().subtract(3, "years");
      return moment(new Date(state.selectedHome.ClosingDateAct)).isSameOrBefore(threeYearsAgo);
    },
  },
  mutations: {
    SET_SHOW_ALERT(state: any, payload: boolean): void {
      state.alert.show = payload;
    },
    SET_IS_NAV_DISABLED(state: any, payload: boolean): void {
      state.isNavDisabled = payload;
    },
    SET_CHANGE_EMAIL_DATA(state: any, payload: any): void {
      state.changeEmail.data = payload;
    },
    SET_TOGGLE_SHOW_CHANGE_EMAIL(state: any, payload: boolean): void {
      state.changeEmail.show = payload;
    },
    SET_ADD_USER_DATA(state: any, payload: any): void {
      state.addUser.data = payload;
    },
    SET_TOGGLE_SHOW_ADD_USER(state: any, payload: boolean): void {
      state.addUser.show = payload;
    },
    SET_UPLOAD_FILE_DATA(state: any, payload: any): void {
      state.uploadFile.data = payload;
    },
    SET_TOGGLE_SHOW_UPLOAD_FILE(state: any, payload: boolean): void {
      state.uploadFile.show = payload;
    },
    SET_TOGGLE_SHOW_SURVEYS(state: any, payload: boolean): void {
      state.showSurveys.show = payload;
    },
    SET_HOMES(state: any, payload: Home[]): void {
      state.homes = payload;
      state.localStorage.setItem("homes", JSON.stringify(payload));
    },
    SET_SELECTED_HOME(state: any, payload: any) {
      state.selectedHome = payload;
      state.localStorage.setItem("selectedHome", JSON.stringify(payload));
    },
    SET_HOA_CONTACTS(state: any, payload: any): void {
      state.hoaContacts = payload;
    },
    SET_USER_PROFILE(state: any, payload: Profile): void {
      state.userProfile = payload;
      state.localStorage.setItem("userProfile", JSON.stringify(payload));
    },
    SET_DOCUMENTS(state: any, payload: any): void {
      state.documents = payload;
    },
    SET_SURVEYS(state: any, payload: any): void {
      state.surveys = payload;
    },
    SET_RELATED_SURVEYS(state: any, payload: any): void {
      state.relatedSurveys = payload;
    },
    SET_USER_TOKEN(state: any, payload: any): void {
      state.security.accessToken = payload;
      state.localStorage.setItem("accessToken", payload);
    },
    SET_REFRESH_TOKEN(state: any, payload: any): void {
      state.security.refreshToken = payload;
      state.localStorage.setItem("refreshToken", payload);
    },
    SET_TOKEN_EXPIRATION(state: any, payload: any): void {
      state.security.expiration = payload;
      state.localStorage.setItem("tokenExpiration", payload);
    },
    SET_SERVICES_ACCESS(state: any, payload: any): void {
      state.security.servicesAccess = payload;
      state.localStorage.setItem("servicesAccess", JSON.stringify(payload));
    },
    SET_USER_ID(state: any, payload: any): void {
      state.security.userID = payload;
      state.localStorage.setItem("userID", payload);
    },
    SET_USER_ROLE(state: any, payload: any): void {
      state.security.role = payload;
      state.localStorage.setItem("role", payload);
    },
    SET_SURVEY_USER(state: any, payload: any): void {
      state.surveyUser = payload;
    },
    SET_MULTIPLE_HOMES(state: any, payload: boolean): void {
      state.security.multipleHomes = payload;
      state.localStorage.setItem("multipleHomes", JSON.stringify(payload));
    },
    SET_JOB_INFO(state: any, payload: any): void {
      state.jobInfo = payload;
      state.localStorage.setItem("jobInfo", JSON.stringify(payload));
    },
    SET_ADMIN_MODE(state: any, payload: any): void {
      state.adminMode = payload;
      state.localStorage.setItem("adminMode", JSON.stringify(payload));
    },
    SET_MOBILE_SIDE_BAR(state: any, payload: any): void {
      state.mobileSideBar.show = payload;
    },
  },
  actions: {
    async setAdminMode(context: any, payload: any): Promise<any> {
      mutations.SET_ADMIN_MODE(context, payload);
    },
    async setShowAlert(context: any, payload: any): Promise<any> {
      mutations.SET_SHOW_ALERT(context, payload);
    },
    async addUser(context: any, payload: any): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken).post(`/homeowner`, payload)).data;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "addUser", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async addHome(context: any, payload: any): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken).put(`/addhome`, payload)).data;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "addHome", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async uploadFile(context: any, payload: any): Promise<any> {
      try {
        switch (payload.get("type")) {
          case "use-and-care-guide": {
            return (await context.state.HTTP(context.getters.getToken).post(`/move-use-and-care-guide`, payload)).data;
          }
          case "waterproofing-document": {
            return (await context.state.HTTP(context.getters.getToken).post(`/move-waterproofing-document`, payload)).data;
          }
          default: {
            return {};
          }
        }
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "uploadFile", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async uploadSelectionsFile(context: any, payload: any): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken).post(`/upload-selections`, payload)).data;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "uploadSelectionsFile", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async deleteSelectionsFile(context: any, jobNumber: string): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken).put(`/delete-selections/${jobNumber}`)).data;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "deleteSelectionsFile", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async changeUserEmail(context: any, payload: any): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken).post(`/updateEmail`, payload)).data;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "changeUserEmail", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async changeUserPassword(context: any, payload: any): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken, process.env.AUTH_BASE_API).post(`password/send`, payload)).data;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "changeUserPassword", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchUserDetails(context: any, userID: number): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken).get(`/user-details/?userID=${userID}`)).data;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchUserDetails", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchUsers(context: any, payload: any): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken).get(`/users/${payload.search}/${payload.useEmail}`)).data;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchUsers", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchJobs(context: any, payload: any): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken).get(`/jobs/${payload.search}`)).data;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchJobs", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchResponsible(context: any): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken).get("/responsible")).data.responsible;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchResponsible", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchAddresses(context: any, payload: any): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken).get(`/addresses/${payload.search}`)).data;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchAddresses", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchHOAContacts(context: any, jobNumber: string): Promise<any> {
      try {
        const result = (await context.state.HTTP(context.getters.getToken).get(`/hoa-contacts/${jobNumber}`)).data;
        mutations.SET_HOA_CONTACTS(context, result.HOA);
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchHOAContacts", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchJobInformation(context: any, jobNumber: string): Promise<any> {
      try {
        return (await context.state.HTTP(context.getters.getToken).get(`/jobInformation/?job_number=${jobNumber}`)).data;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchJobInformation", exception: e });
        throw Error(e);
      }
    },
    async fetchProductType(context: any, jobInfo: any): Promise<any> {
      let sectionInfo = { specLevel: "0" };
      const productTypes: any = {
        PH: process.env.PH_PRODUCT_TYPE ? process.env.PH_PRODUCT_TYPE.split(",") : [],
        SF: process.env.SF_PRODUCT_TYPE ? process.env.SF_PRODUCT_TYPE.split(",") : [],
        AT: process.env.AT_PRODUCT_TYPE ? process.env.AT_PRODUCT_TYPE.split(",") : [],
        MSH: process.env.MSH_PRODUCT_TYPE ? process.env.MSH_PRODUCT_TYPE.split(",") : [],
      };
      const siteInfo = (await context.state.HTTP(context.getters.getToken).get(`/siteInformation/?job_number=${jobInfo.JobNumber}`)).data;
      if (siteInfo.specLevel === sectionInfo.specLevel) {
        sectionInfo = (await context.state.HTTP(context.getters.getToken).get(`/sectionInformation/?section_id=${siteInfo.sectionId}`)).data;
        return determineProductType(productTypes, sectionInfo);
      } else {
        return determineProductType(productTypes, siteInfo);
      }
    },
    async fetchSiteInformation(context: any, jobInfo: any): Promise<any> {
      try {
        const ProductType = await context.dispatch("fetchProductType", jobInfo);

        mutations.SET_JOB_INFO(context, {
          jobNumber: jobInfo.JobNumber,
          division: jobInfo.Division,
          community: jobInfo.Community,
          productType: ProductType,
          agreementWritten: jobInfo.AgreementWritten,
          company: jobInfo.CompanyShortName,
          responsible: jobInfo.Responsible,
        });
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchSiteInformation", exception: e });
        throw Error(e);
      }
    },
    async fetchHomes(context: any, userID: string): Promise<any> {
      try {
        const homes = (await context.state.HTTP(context.getters.getToken).get(`/homeList/?userID=${userID}`)).data.homes;
        mutations.SET_HOMES(context, homes);
        mutations.SET_MULTIPLE_HOMES(context, homes.length > 1);
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchHomes", exception: e });
        throw Error(e);
      }
    },
    async fetchUserProfile(context: any, userID: string): Promise<any> {
      try {
        const userProfile = (await context.state.HTTP(context.getters.getToken).get(`/userProfile/${userID}`)).data;
        mutations.SET_USER_PROFILE(context, userProfile && userProfile.user ? userProfile.user : context.state.userProfile);
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchUserProfile", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async changeUserProfile(context: any, payload: any): Promise<any> {
      try {
        await context.state.HTTP(context.getters.getToken).post(`/userProfile/${payload.userID}`, {
          name: payload.name,
          email: payload.email,
          phoneHome: payload.phoneHome,
          phoneWork: payload.phoneWork,
          relationID: payload.relationID,
        });
        mutations.SET_USER_PROFILE(context, payload);
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "changeUserProfile", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async submitServiceForm(context: any, payload: any): Promise<any> {
      try {
        const serviceForm = (await context.state.HTTP(context.getters.getToken).post(`/services`, payload)).data.res;
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "submitServiceForm", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async submitRebateForm(context: any, payload: any): Promise<any> {
      try {
        const rebateForm = (await context.state.HTTP(context.getters.getToken).post(`/rebates`, payload)).data.res;
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "submitRebateForm", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async submitSurveyForm(context: any, payload: any): Promise<any> {
      try {
        const surveyForm = (await context.state.HTTP(context.getters.getToken).post(`/surveys`, payload)).data.res;
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "submitSurveyForm", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async submitContactUs(context: any, payload: any): Promise<any> {
      try {
        const contactUs = (await context.state.HTTP(context.getters.getToken).post(`/contact-us`, payload)).data.res;
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "submitContactUs", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchDocuments(context: any, payload: any): Promise<any> {
      try {
        const response = (await context.state.HTTP(context.getters.getToken).post(`/documents`, payload)).data;
        mutations.SET_DOCUMENTS(context, response.documents);
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchDocuments", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchHomeDocuments(context: any, payload: any): Promise<any> {
      try {
        const response = (await context.state.HTTP(context.getters.getToken).post(`/get-home-docs`, payload)).data;
        return response;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchHomeDocuments", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchSapphireFile(context: any, payload: any): Promise<any> {
      try {
        const response = (await context.state.HTTP(context.getters.getToken).post(`/sapphireFile`, payload)).data;
        return response;
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchSapphireFile", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchSurveys(context: any, payload: any): Promise<any> {
      try {
        const response = (
          await context.state.HTTP(context.getters.getToken).get(`/surveys/${payload.jobNumber}${payload.surveyType ? `/${payload.surveyType}` : ""}`)
        ).data;
        if (response && response.surveys && response.surveys.length > 0) {
          mutations.SET_SURVEYS(context, response.surveys);
        } else {
          mutations.SET_SURVEYS(context, []);
        }
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchSurveys", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async fetchRelatedSurveys(context: any, payload: any): Promise<any> {
      try {
        const response = (
          await context.state
            .HTTP(context.getters.getToken)
            .get(`/relatedSurveys/${payload.jobNumber}${payload.surveyType ? `/${payload.surveyType}` : ""}`)
        ).data;
        if (response && response.surveys && response.surveys.length > 0) {
          mutations.SET_RELATED_SURVEYS(context, response.surveys);
        } else {
          mutations.SET_RELATED_SURVEYS(context, []);
        }
        return { error: false };
      } catch (e) {
        await context.dispatch("sentryError", { component: "store.ts", function: "fetchSurveys", exception: e });
        return {
          error: true,
          errorMsg: e,
        };
      }
    },
    async setUserToken(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_USER_TOKEN(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async setRefreshToken(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_REFRESH_TOKEN(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async setTokenExpiration(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_TOKEN_EXPIRATION(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async setUserID(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_USER_ID(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async setUserRole(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_USER_ROLE(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async setSurveyUser(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_SURVEY_USER(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async setSelectedHome(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_SELECTED_HOME(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async toggleShowChangeEmail(context: any, payload: boolean): Promise<any> {
      try {
        mutations.SET_TOGGLE_SHOW_CHANGE_EMAIL(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async setChangeEmailData(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_CHANGE_EMAIL_DATA(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async toggleShowAddUser(context: any, payload: boolean): Promise<any> {
      try {
        mutations.SET_TOGGLE_SHOW_ADD_USER(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async setAddUserData(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_ADD_USER_DATA(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async toggleShowUploadFile(context: any, payload: boolean): Promise<any> {
      try {
        mutations.SET_TOGGLE_SHOW_UPLOAD_FILE(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async toggleShowSurveys(context: any, payload: boolean): Promise<any> {
      try {
        mutations.SET_TOGGLE_SHOW_SURVEYS(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async setUploadFileData(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_UPLOAD_FILE_DATA(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async setServicesAccess(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_SERVICES_ACCESS(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async setIsNavDisabled(context: any, payload: boolean): Promise<any> {
      try {
        mutations.SET_IS_NAV_DISABLED(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
    async sentrySetUser(context: any): Promise<any> {
      try {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: context.getters.getUserID,
            name: context.getters.getName,
            email: context.getters.getEmail,
            phoneHome: context.getters.getPhoneHome,
            phoneWork: context.getters.getPhoneWork,
            role: context.getters.getUserRole,
            multipleHomes: context.getters.getMultipleHomes,
            jobs: context.getters.getHomes,
            access_token: context.getters.getToken,
            refresh_token: context.getters.getRefreshToken,
          });
        });
      } catch (e) {
        throw Error(e);
      }
    },
    async sentryError(context: any, payload: any): Promise<any> {
      try {
        return Sentry.captureMessage(`[${payload.component}] ${payload.function}: ${payload.exception}`);
      } catch (e) {
        throw Error(e);
      }
    },
    async setMobileSideBar(context: any, payload: any): Promise<any> {
      try {
        mutations.SET_MOBILE_SIDE_BAR(context, payload);
        return true;
      } catch (e) {
        throw Error(e);
      }
    },
  },
};

export const createStore = () => new Vuex.Store<stateStore>(storeState);

const { commit, read, dispatch } = getStoreAccessors<stateStore, stateStore>("");

const moduleGetters = storeState.getters;

export const getters = {
  getIsNavDisabled: read(moduleGetters.getIsNavDisabled),
  getChangeEmailData: read(moduleGetters.getChangeEmailData),
  getShowChangeEmail: read(moduleGetters.getShowChangeEmail),
  getAddUserData: read(moduleGetters.getAddUserData),
  getShowAddUser: read(moduleGetters.getShowAddUser),
  getUploadFileData: read(moduleGetters.getUploadFileData),
  getShowUploadFile: read(moduleGetters.getShowUploadFile),
  getShowSurveys: read(moduleGetters.getShowSurveys),
  getSurveyUser: read(moduleGetters.getSurveyUser),
  getHomes: read(moduleGetters.getHomes),
  getName: read(moduleGetters.getName),
  getEmail: read(moduleGetters.getEmail),
  getPhoneHome: read(moduleGetters.getPhoneHome),
  getPhoneWork: read(moduleGetters.getPhoneWork),
  getUserProfile: read(moduleGetters.getUserProfile),
  getToken: read(moduleGetters.getToken),
  getRefreshToken: read(moduleGetters.getRefreshToken),
  getTokenExpiration: read(moduleGetters.getTokenExpiration),
  getUserID: read(moduleGetters.getUserID),
  getCode: read(moduleGetters.getCode),
  getDocuments: read(moduleGetters.getDocuments),
  getUserRole: read(moduleGetters.getUserRole),
  getHOAContacts: read(moduleGetters.getHOAContacts),
  getMultipleHomes: read(moduleGetters.getMultipleHomes),
  getSelectedHome: read(moduleGetters.getSelectedHome),
  getJobInfo: read(moduleGetters.getJobInfo),
  getAdminMode: read(moduleGetters.getAdminMode),
  getSurveys: read(moduleGetters.getSurveys),
  getRelatedSurveys: read(moduleGetters.getRelatedSurveys),
  getServicesAccess: read(moduleGetters.getServicesAccess),
  getShowAlert: read(moduleGetters.getShowAlert),
  getMobileSideBar: read(moduleGetters.getMobileSideBar),
  getJUser: read(moduleGetters.getJUser),
  getIsRebatesDisabled: read(moduleGetters.getIsRebatesDisabled),
};

const moduleMutations = storeState.mutations;

export const mutations = {
  SET_SHOW_ALERT: commit(moduleMutations.SET_SHOW_ALERT),
  SET_IS_NAV_DISABLED: commit(moduleMutations.SET_IS_NAV_DISABLED),
  SET_ADD_USER_DATA: commit(moduleMutations.SET_ADD_USER_DATA),
  SET_TOGGLE_SHOW_ADD_USER: commit(moduleMutations.SET_TOGGLE_SHOW_ADD_USER),
  SET_UPLOAD_FILE_DATA: commit(moduleMutations.SET_UPLOAD_FILE_DATA),
  SET_TOGGLE_SHOW_UPLOAD_FILE: commit(moduleMutations.SET_TOGGLE_SHOW_UPLOAD_FILE),
  SET_TOGGLE_SHOW_SURVEYS: commit(moduleMutations.SET_TOGGLE_SHOW_SURVEYS),
  SET_CHANGE_EMAIL_DATA: commit(moduleMutations.SET_CHANGE_EMAIL_DATA),
  SET_HOMES: commit(moduleMutations.SET_HOMES),
  SET_USER_PROFILE: commit(moduleMutations.SET_USER_PROFILE),
  SET_USER_TOKEN: commit(moduleMutations.SET_USER_TOKEN),
  SET_DOCUMENTS: commit(moduleMutations.SET_DOCUMENTS),
  SET_SURVEYS: commit(moduleMutations.SET_SURVEYS),
  SET_RELATED_SURVEYS: commit(moduleMutations.SET_RELATED_SURVEYS),
  SET_USER_ID: commit(moduleMutations.SET_USER_ID),
  SET_REFRESH_TOKEN: commit(moduleMutations.SET_REFRESH_TOKEN),
  SET_TOKEN_EXPIRATION: commit(moduleMutations.SET_TOKEN_EXPIRATION),
  SET_USER_ROLE: commit(moduleMutations.SET_USER_ROLE),
  SET_SURVEY_USER: commit(moduleMutations.SET_SURVEY_USER),
  SET_HOA_CONTACTS: commit(moduleMutations.SET_HOA_CONTACTS),
  SET_MULTIPLE_HOMES: commit(moduleMutations.SET_MULTIPLE_HOMES),
  SET_SELECTED_HOME: commit(moduleMutations.SET_SELECTED_HOME),
  SET_JOB_INFO: commit(moduleMutations.SET_JOB_INFO),
  SET_ADMIN_MODE: commit(moduleMutations.SET_ADMIN_MODE),
  SET_SERVICES_ACCESS: commit(moduleMutations.SET_SERVICES_ACCESS),
  SET_TOGGLE_SHOW_CHANGE_EMAIL: commit(moduleMutations.SET_TOGGLE_SHOW_CHANGE_EMAIL),
  SET_MOBILE_SIDE_BAR: commit(moduleMutations.SET_MOBILE_SIDE_BAR),
};

const moduleActions = storeState.actions;

export const actions = {
  setAdminMode: dispatch(moduleActions.setAdminMode),
  setShowAlert: dispatch(moduleActions.setShowAlert),
  addUser: dispatch(moduleActions.addUser),
  addHome: dispatch(moduleActions.addHome),
  setAddUserData: dispatch(moduleActions.setAddUserData),
  toggleShowAddUser: dispatch(moduleActions.toggleShowAddUser),
  uploadFile: dispatch(moduleActions.uploadFile),
  uploadSelectionsFile: dispatch(moduleActions.uploadSelectionsFile),
  deleteSelectionsFile: dispatch(moduleActions.deleteSelectionsFile),
  setUploadFileData: dispatch(moduleActions.setUploadFileData),
  toggleShowUploadFile: dispatch(moduleActions.toggleShowUploadFile),
  toggleShowSurveys: dispatch(moduleActions.toggleShowSurveys),
  changeUserEmail: dispatch(moduleActions.changeUserEmail),
  changeUserPassword: dispatch(moduleActions.changeUserPassword),
  fetchUserDetails: dispatch(moduleActions.fetchUserDetails),
  fetchUsers: dispatch(moduleActions.fetchUsers),
  fetchJobs: dispatch(moduleActions.fetchJobs),
  fetchResponsible: dispatch(moduleActions.fetchResponsible),
  fetchAddresses: dispatch(moduleActions.fetchAddresses),
  fetchHOAContacts: dispatch(moduleActions.fetchHOAContacts),
  fetchHomes: dispatch(moduleActions.fetchHomes),
  fetchJobInformation: dispatch(moduleActions.fetchJobInformation),
  fetchSiteInformation: dispatch(moduleActions.fetchSiteInformation),
  fetchUserProfile: dispatch(moduleActions.fetchUserProfile),
  changeUserProfile: dispatch(moduleActions.changeUserProfile),
  submitServiceForm: dispatch(moduleActions.submitServiceForm),
  submitSurveyForm: dispatch(moduleActions.submitSurveyForm),
  submitRebateForm: dispatch(moduleActions.submitRebateForm),
  fetchDocuments: dispatch(moduleActions.fetchDocuments),
  fetchHomeDocuments: dispatch(moduleActions.fetchHomeDocuments),
  fetchSapphireFile: dispatch(moduleActions.fetchSapphireFile),
  submitContactUs: dispatch(moduleActions.submitContactUs),
  setUserToken: dispatch(moduleActions.setUserToken),
  setUserID: dispatch(moduleActions.setUserID),
  setRefreshToken: dispatch(moduleActions.setRefreshToken),
  setTokenExpiration: dispatch(moduleActions.setTokenExpiration),
  setUserRole: dispatch(moduleActions.setUserRole),
  setSurveyUser: dispatch(moduleActions.setSurveyUser),
  setSelectedHome: dispatch(moduleActions.setSelectedHome),
  setServicesAccess: dispatch(moduleActions.setServicesAccess),
  setChangeEmailData: dispatch(moduleActions.setChangeEmailData),
  toggleShowChangeEmail: dispatch(moduleActions.toggleShowChangeEmail),
  setIsNavDisabled: dispatch(moduleActions.setIsNavDisabled),
  sentryError: dispatch(moduleActions.sentryError),
  sentrySetUser: dispatch(moduleActions.sentrySetUser),
  fetchSurveys: dispatch(moduleActions.fetchSurveys),
  fetchRelatedSurveys: dispatch(moduleActions.fetchRelatedSurveys),
  setMobileSideBar: dispatch(moduleActions.setMobileSideBar),
};

import { actions, getters } from "./../store/store";
export default function determineAccessToServices(store: any): any {
  const { ClosingRatingEntered, ClosingDateAct, CorporateConfCls, is30DaysExpired, is180DaysExpired, is15MoExpired } = getters.getSelectedHome(store);
  const noClosingDateAct: any = ["/standard-service"];
  const always: any = ["/standard-service"];
  const Before30Days: any = ["/closing-survey", ...always];
  const Before180Days: any = ["/initial-service", "/initial-survey", "/initial-rebate", ...always];
  const Before15Mo: any = ["/final-rebate", "/final-service", "/final-survey", ...always];

  if (!ClosingDateAct && !CorporateConfCls) {
    return noClosingDateAct;
  } else if (!ClosingDateAct && CorporateConfCls && !ClosingRatingEntered) {
    return Before30Days;
  } else if (ClosingDateAct && !is30DaysExpired && !ClosingRatingEntered) {
    return Before30Days;
  } else if (is30DaysExpired && !is180DaysExpired) {
    return Before180Days;
  } else if (is180DaysExpired && !is15MoExpired) {
    return Before15Mo;
  } else if (is30DaysExpired && is180DaysExpired && is15MoExpired) {
    return always;
  } else {
    return ["/standard-service"];
  }
}

import Vue from "vue";
import {
  Vuetify,
  VApp,
  VCombobox,
  VTextField,
  VTextarea,
  VList,
  VBtn,
  VBtnToggle,
  VIcon,
  VGrid,
  VToolbar,
  transitions,
  VForm,
  VCard,
  VSelect,
  VDataTable,
  VHover,
  VSwitch,
  VMenu,
  VCheckbox,
  VRadioGroup,
  VProgressLinear,
  VProgressCircular,
  VExpansionPanel,
  VSnackbar,
  VDatePicker,
  VDialog,
  VNavigationDrawer,
} from "vuetify";
import { VAutocomplete } from "vuetify/lib";
import "@fortawesome/fontawesome-free/css/all.css";
import "vuetify/src/stylus/app.styl";

Vue.use(Vuetify, {
  components: {
    VApp,
    VCombobox,
    VTextField,
    VTextarea,
    VList,
    VBtn,
    VBtnToggle,
    VIcon,
    VGrid,
    VToolbar,
    transitions,
    VCard,
    VForm,
    VSelect,
    VAutocomplete,
    VDataTable,
    VHover,
    VSwitch,
    VMenu,
    VCheckbox,
    VRadioGroup,
    VProgressLinear,
    VProgressCircular,
    VExpansionPanel,
    VSnackbar,
    VDatePicker,
    VDialog,
    VNavigationDrawer,
  },
  iconfont: "fa",
  theme: {
    primary: "#2196F3",
    secondary: "#2DB4D4",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
  },
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "app", attrs: { dark: _vm.getTheme } },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "hidden-sm-and-up",
          attrs: { absolute: "" },
          on: { input: _vm.handleDrawerChange },
          model: {
            value: _vm.drawer.show,
            callback: function($$v) {
              _vm.$set(_vm.drawer, "show", $$v)
            },
            expression: "drawer.show"
          }
        },
        [
          _c(
            "v-list",
            { staticClass: "pa-1" },
            _vm._l(_vm.getDrawerOptions(), function(item) {
              return _c(
                "v-list-tile",
                { key: item.label, attrs: { to: item.to } },
                [
                  _c(
                    "v-list-tile-action",
                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                    1
                  ),
                  _c(
                    "v-list-tile-content",
                    [_c("v-list-tile-title", [_vm._v(_vm._s(item.label))])],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("router-view", { attrs: { name: "Alert" } }),
      _c("router-view", { attrs: { name: "NavBar" } }),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import "./plugins/vuetify";
import { createStore } from "./store/store";
import router from "./util/router";
import "font-awesome-animation/dist/font-awesome-animation.css";

Vue.use(Vuex);

Vue.config.productionTip = false;

new Vue({
  store: createStore(),
  router,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import Router from "vue-router";
import { storeState } from "./../store/store";
import CodeGrant from "./../util/CodeGrant";
import { detect } from "detect-browser";

// Reused UI Components
const NavBar = () => import(/* webpackChunkName: "NavBar.vue" */ "./../components/common/NavBar.vue");
const Alert = () => import(/* webpackChunkName: "Alert.vue" */ "./../components/common/Alert.vue");

Vue.use(Router);

function accessToServicesRoutes(route: string, isJUser: boolean): any {
  if (window.localStorage.getItem("servicesAccess")) {
    const access = JSON.parse(window.localStorage.getItem("servicesAccess") as any);
    if (isJUser) {
      access.push("/closing-survey", '/initial-survey', '/final-survey');
    }
    if (access) {
      return access.includes(route);
    }
    return false;
  } else {
    return false;
  }
}

function servicesRoutes(route: string): any {
  const serviceRoutes: any = [
    "/standard-service",
    "/closing-survey",
    "/initial-service",
    "/initial-survey",
    "/initial-rebate",
    "/final-rebate",
    "/final-service",
    "/final-survey",
  ];
  return serviceRoutes.includes(route);
}

function userProtectedRoute(route: string) {
  const userRoutes = [
    "/documents",
    "/service-requests",
    "/service-requests-rebates",
    "/service-requests-services",
    "/contact-us",
    "/closing-information",
    "/survey",
    "/closing-survey",
    "/initial-survey",
    "/final-survey",
    "/hoa-contacts",
    "/initial-rebate",
    "/final-rebate",
    "/initial-service",
    "/final-service",
    "/standard-service",
  ];

  return userRoutes.includes(route);
}

function canParseLocalStorage(item: string): boolean {
  try {
    JSON.parse(window.localStorage.getItem(item) as any);
    return true;
  } catch {
    return false;
  }
}

const navGuard = (to: any, from: any, next: any) => {
  const grant = new CodeGrant(storeState, router);
  const isJUser = storeState.getters.getJUser(storeState.state);
  // If the person is trying to hit a route without a token
  if (!window.localStorage.getItem("accessToken")) {
    next({ path: "/forbidden" });
    // Prevent someone who doesn"t have multiple homes from viewing the "dashboard"  
  } else if (to.path === "/dashboard" && !canParseLocalStorage("multipleHomes")) {
    next({ path: "/forbidden" });
    // Prevent someone who is not logged in or doesn"t have permission to view a page
  } else if (window.localStorage.getItem("accessToken") && grant.doesUserHavePermissionTo(to.path)) {
    const isAServiceRoute = servicesRoutes(to.path);

    if (isAServiceRoute) {
      if (accessToServicesRoutes(to.path, isJUser)) {
        next();
      } else {
        next({ path: "/forbidden" });
      }
    } else {
      next();
    }
  } else {
    next({ path: "/forbidden" });
  }
};

const callbackGuard = (to: any, from: any, next: any) => {
  const grant = new CodeGrant(storeState, router);
  const fromOAuth = grant.getParameterFromAppUrl("code");
  if (from.path === "/" && fromOAuth.length === 0) {
    next({ path: "/landing" });
  }
  if (from.path === "/dashboard" && to.path === "/callback") {
    if (window.localStorage.getItem("accessToken")) {
      next({ path: "/logout" });
    } else {
      next();
    }
  } else {
    if (window.localStorage.getItem("accessToken")) {
      next();
    } else {
      next();
    }
  }
};
const browser = detect();
let routeArray;
if (
  browser &&
  ((browser.name === "chrome" && parseInt(browser.version, 10) >= 44) ||
    (browser.name === "edge-chromium" && parseInt(browser.version, 10) >= 78) ||
    (browser.name === "edge" && parseInt(browser.version, 10) >= 17) ||
    (browser.name === "firefox" && parseInt(browser.version, 10) >= 35) ||
    (browser.name === "safari" && parseInt(browser.version, 10) >= 10) ||
    (browser.name === "ios" && parseInt(browser.version, 10) >= 10))
) {
  routeArray = [
    {
      path: "/landing",
      name: "landing",
      components: { default: () => import(/* webpackChunkName: "Landing.vue" */ "./../components/common/Landing.vue") },
    },
    {
      path: "/callback",
      name: "callback",
      component: () => import(/* webpackChunkName: "Callback.vue" */ "./../components/common/Callback.vue"),
      beforeEnter: callbackGuard,
    },
    {
      path: "/",
      redirect: "/callback",
    },
    {
      path: "/forbidden",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "Forbidden.vue" */ "./../components/common/Forbidden.vue"),
      },
    },
    {
      path: "/logout",
      component: () => import(/* webpackChunkName: "Logout.vue" */ "./../components/common/Logout.vue"),
      beforeEnter: navGuard,
    },
    {
      path: "/dashboard",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "Dashboard.vue" */ "./../components/Dashboard.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/documents",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "Documents.vue" */ "./../components/Dashboard/Documents.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/service-requests",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "ServiceRequests.vue" */ "./../components/Dashboard/ServiceRequests.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/service-requests-rebates",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "Rebates.vue" */ "./../components/Dashboard/ServiceRequests/Rebates.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/service-requests-services",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "Services.vue" */ "./../components/Dashboard/ServiceRequests/Services.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/user-profile",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "UserProfile.vue" */ "./../components/Dashboard/UserProfile.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/contact-us",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "ContactUs.vue" */ "./../components/Dashboard/ContactUs.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/closing-information",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "ClosingInformation.vue" */ "./../components/Dashboard/ClosingInformation.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/survey",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "Survey.vue" */ "./../components/Dashboard/Survey.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/closing-survey",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "ClosingSurvey.vue" */ "./../components/Dashboard/Surveys/ClosingSurvey.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/initial-survey",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "InitialSurvey.vue" */ "./../components/Dashboard/Surveys/InitialSurvey.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/final-survey",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "FinalSurvey.vue" */ "./../components/Dashboard/Surveys/FinalSurvey.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/hoa-contacts",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "HoaContacts.vue" */ "./../components/Dashboard/HoaContacts.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/initial-rebate",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "InitialRebate.vue" */ "./../components/Dashboard/ServiceRequests/Rebates/InitialRebate.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/final-rebate",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "FinalRebate.vue" */ "./../components/Dashboard/ServiceRequests/Rebates/FinalRebate.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/initial-service",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "InitialService.vue" */ "./../components/Dashboard/ServiceRequests/Services/InitialService.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/final-service",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "FinalService.vue" */ "./../components/Dashboard/ServiceRequests/Services/FinalService.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/standard-service",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "StandardService.vue" */ "./../components/Dashboard/ServiceRequests/Services/StandardService.vue"),
      },
      beforeEnter: navGuard,
    },
    {
      path: "/admin",
      components: {
        NavBar,
        Alert,
        default: () => import(/* webpackChunkName: "Admin.vue" */ "./../components/Admin.vue"),
      },
      beforeEnter: navGuard,
    },
    // Case where users are trying to access domino legacy links
    {
      path: "/domino/*",
      redirect: "/callback",
    },
    {
      path: "*",
      components: {
        NavBar,
        default: () => import(/* webpackChunkName: "FourOhFour.vue" */ "./../components/common/FourOhFour.vue"),
      },
    },
  ];
} else {
  routeArray = [
    {
      path: "*",
      component: () => import(/* webpackChunkName: "Unsupported.vue" */ "./../components/common/Unsupported.vue"),
    },
  ];
}

const router = new Router({
  mode: "history",
  routes: routeArray,
});

export default router;


























import Vue from "vue";
import { Component } from "vue-property-decorator";
import { getters, actions } from "./store/store";
import isEmpty from "./util/isEmpty";

@Component({
  name: "App",
})
export default class App extends Vue {
  public drawer: any = getters.getMobileSideBar(this.$store);
  public handleDrawerChange(e): void {
    if (e === false) {
      actions.setMobileSideBar(this.$store, false)
    }
  }
  private options: any = [
    {
      icon: "fas fa-th",
      label: "Dashboard",
      to: "dashboard",
      toolbar: null,
      menu: "hidden-sm-and-down",
    },
    {
      icon: "fas fa-file-alt",
      label: "Documents",
      to: "documents",
      toolbar: null,
      menu: "hidden-sm-and-down",
    },
    {
      icon: "fab fa-wpforms",
      label: "Rebates & Services",
      to: "service-requests",
      toolbar: null,
      menu: "hidden-sm-and-down",
    },
    {
      icon: "fas fa-poll",
      label: "Surveys",
      to: "survey",
      toolbar: null,
      menu: "hidden-sm-and-down",
    },
    {
      icon: "fas fa-address-card",
      label: "HOA Contacts",
      to: "hoa-contacts",
      toolbar: null,
      menu: "hidden-sm-and-down",
    },
    {
      icon: "fas fa-envelope",
      label: "Contact Us",
      to: "contact-us",
      toolbar: null,
      menu: "hidden-sm-and-down",
    },
    {
      icon: "fas fa-user-circle",
      label: "Profile",
      to: "user-profile",
      toolbar: "hidden-xs-only",
      menu: null,
    },
    {
      icon: "fas fa-users-cog",
      label: "Admin",
      to: "/admin",
      toolbar: "hidden-xs-only",
      menu: null,
    },
    {
      icon: "fas fa-sign-out-alt",
      label: "Sign Out",
      to: "/logout",
      toolbar: "hidden-xs-only",
      menu: null,
      show: false,
    },
  ];
  public getDrawerOptions(): any {
    const showDashboard = getters.getMultipleHomes(this.$store) ? JSON.parse(getters.getMultipleHomes(this.$store) as any) : false;
    const noHomeSelected = isEmpty(getters.getSelectedHome(this.$store));
    const role = getters.getUserRole(this.$store);
    let optionsSlice = this.options.slice();
    if (this.getIsRebatesDisabled && !this.getIsJUser) {
      optionsSlice = this.options.filter((o) => o.label !== "Rebates & Services");
    }

    if (showDashboard) {
      if (noHomeSelected) {
        if (role === "both") {
          return optionsSlice.filter((o) => o.label === "Admin" || o.label === "Profile" || o.label === "Sign Out" || o.label === "Dashboard");
        } else if (role === "admin") {
          return optionsSlice.filter((o) => o.label === "Admin" || o.label === "Profile" || o.label === "Sign Out");
        } else {
          return optionsSlice.filter((o) => o.label === "Profile" || o.label === "Sign Out" || o.label === "Dashboard");
        }
      } else {
        if (role === "both" || role === "admin") {
          return optionsSlice;
        } else {
          return optionsSlice.filter((o) => o.label !== "Admin");
        }
      }
    } else {
      if (role === "both" || role === "admin") {
        return optionsSlice.filter((o) => o.label !== "Dashboard");
      } else {
        return optionsSlice.filter((o) => o.label !== "Admin" && o.label !== "Dashboard");
      }
    }
  }

  public isScreenSizeSmall(): boolean {
    return window.innerWidth <= 500;
  }
  public get getTheme(): boolean {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }
  public get getIsRebatesDisabled(): boolean {
    return getters.getIsRebatesDisabled(this.$store);
  }
  public get getIsJUser(): boolean {
    return getters.getJUser(this.$store);
  }
}
